import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setFormOpenState } from 'state/slices/ui/form'
import CloseButton from 'components/common/CloseButton'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { selectTxStatus } from 'state/slices/ui/transaction'
import { useEnvironment } from 'App'
import { TxInputClaim } from './TxInputClaim'
import LineItemList from 'components/common/LineItemList'
import { formatCurrencyPrecise } from 'toolbox/format'
import { LineItemProps } from 'components/common/LineItem'
import { Aptos, AptosConfig } from '@aptos-labs/ts-sdk'
import { Network } from 'aptos'
import { doClaimTx, TxReqPayloadClaim } from 'state/thunks/doClaimTx'
import { selectCoinTypesWithDecimals } from 'state/slices/app/vaults'

export function ClaimForm({
  value,
  isDetails,
  tokenBalance
}: {
  value: number
  isDetails?: boolean
  tokenBalance: number
}) {
  const { connected } = useWallet()
  const dispatch = useAppDispatch()
  const { account, signAndSubmitTransaction } = useWallet()
  const isTxPending = useAppSelector(selectTxStatus) === 'pending'
  const { config } = useEnvironment()
  const aptosConfig = new AptosConfig({
    network: config.network as Network,
    fullnode: config.aptosNodeUrl,
    faucet: config.aptosFaucetUrl
  })
  const coinTypeWithDecimals = useAppSelector(selectCoinTypesWithDecimals)
  const aptos = new Aptos(aptosConfig)

  const disabled = isTxPending || value === 0 || !value || !connected
  const buttonText = !connected
    ? 'Connect wallet'
    : isTxPending
    ? 'Transaction pending...'
    : value === 0 || !value
    ? 'No rewards to claim'
    : 'Claim'
  const onClick = () => {}

  const valDisplay = `${tokenBalance ? tokenBalance / 10 ** 8 : 0} APT ${formatCurrencyPrecise(
    value ? value : 0
  )}`

  const lineItemListData: LineItemProps[] = [
    {
      label: 'APT',
      value: valDisplay
    }
  ]

  const payload: TxReqPayloadClaim = {
    address: account?.address as string,
    signAndSub: signAndSubmitTransaction,
    aptos: aptos,
    envNetwork: config.network,
    API_URL: config.positionApiUrl,
    nodeUrl: config.aptosNodeUrl,
    stakeAddress: config.stakeAddress,
    coinTypeWithDecimals
  }

  const doClaim = () => {
    dispatch(doClaimTx(payload))
  }

  return (
    <div className="tx-form claim">
      {!isDetails && <CloseButton cb={() => setFormOpenState(false)} white />}
      <h3>Claim all rewards</h3>
      <TxInputClaim inputVal={value ? value : 0} />
      <p className="claim-label">Rewards</p>
      <hr className="hr" />

      <LineItemList items={lineItemListData} />
      <div className="tx-form-buttons">
        {/* <button
          className="btn rounded primary type-2 type-grey"
          onClick={() => setIsReviewState(false)}>
          Cancel
        </button> */}

        <button className="btn full-w rounded primary type-2" disabled={disabled} onClick={doClaim}>
          {buttonText}
        </button>
      </div>
    </div>
  )
}
