import DoubleDataPoint, { DoubleDataPointProps } from 'components/common/DoubleDataPoint'
import { MetaToken, MetaTokenProps } from 'components/common/MetaToken'
import { formatCurrency, prettyTokenBal } from 'toolbox/format'
import { setActiveVaultState, setFormOpenState, setIsManageState } from 'state/slices/ui/form'
import { useState } from 'react'
import { setExploreVaultDetailsOpen } from 'state/slices/ui/explore'
import { LIQUIDSWAP, TYPE_COLOR_MAP, UnifiedVault } from 'state/types'
import {
  selectLoadedWalletBalances,
  getWalletBalance,
  selectWalletBalancesLoaded
} from 'state/slices/user/walletBalances'
import { useAppSelector } from 'state/hooks'
import { VaultDataPoints } from './VaultDataPoints'

export interface VaultRowProps {
  vault: UnifiedVault
  minimal?: boolean
}

export function VaultRow({ vault, minimal }: VaultRowProps) {
  const { coinType, meta } = vault
  const { iconURL, displayName, investmentType } = meta
  const [isHovered, setIsHovered] = useState(false)
  const onHover = () => setIsHovered(true)
  const onHoverOut = () => setIsHovered(false)
  const isLiquidSwap = vault.meta.investmentType === LIQUIDSWAP

  const isWalletLoaded = useAppSelector(selectWalletBalancesLoaded)
  const isUserLoaded = isWalletLoaded
  const balances = useAppSelector(selectLoadedWalletBalances)

  const walletBalance = getWalletBalance(balances, coinType)

  const labelColor = TYPE_COLOR_MAP[investmentType]

  const createLabels = vault.meta.labels.map((label) => ({ text: label, colorClass: '' }))

  const tokenMeta: MetaTokenProps = {
    icon: iconURL,
    ticker: displayName,
    pills: [{ text: investmentType, colorClass: labelColor }, ...createLabels],
    useLayerToken: isLiquidSwap
  }

  const userPosition = vault.userPosition?.balance || 0
  const hasPosition = userPosition > 0

  const doubleDataPoint: DoubleDataPointProps = {
    topLabel: `My position`,
    topValue: `${formatCurrency(vault.userPosition?.value ?? 0)}`,
    bottomLabel: 'Wallet balance',
    bottomValue: `${prettyTokenBal(walletBalance)} ${displayName}`,
    showTop: isUserLoaded && hasPosition
  }

  const useCb = (e: React.MouseEvent) => {
    e.stopPropagation()
    setActiveVaultState(vault)
    setFormOpenState(true)
    setIsManageState(hasPosition)
  }

  const action = hasPosition ? (
    <button onClick={useCb} className="btn primary rounded nowrap">
      Manage liquidity
    </button>
  ) : (
    <button onClick={useCb} className="btn primary type-a rounded nowrap">
      Deposit
    </button>
  )

  function openDetails() {
    setActiveVaultState(vault)
    setExploreVaultDetailsOpen(true)
  }

  const minimalClass = minimal ? 'minimal' : ''
  const isHoverClass = isHovered ? 'hovered' : ''

  return (
    <div
      className={'vault-row ' + minimalClass + ' ' + isHoverClass}
      onMouseEnter={onHover}
      onMouseLeave={onHoverOut}
      onClick={openDetails}>
      <div className="left">
        <div className="vault-meta">
          <MetaToken {...tokenMeta} />
        </div>
        <VaultDataPoints vault={vault} />
      </div>
      {!minimal && (
        <div className="right">
          <DoubleDataPoint {...doubleDataPoint} />
          <div className="action-btns">{action}</div>
        </div>
      )}
    </div>
  )
}
