import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchVaults, LIQUID_USDT } from 'state/thunks/fetchVaults'
import { UnifiedVault } from 'state/types'
import { selectBrokersAsUnified, selectBrokersLoaded } from './brokers'
import { getWalletBalance, selectWalletBalances } from '../user/walletBalances'
import { selectStakedBalances } from '../user/stakedBalances'

type State = Loadable<UnifiedVault[]>
export const initialState = initLoadable<UnifiedVault[]>([])

const vaultsSlice = createSlice({
  name: 'vaults',
  initialState,
  reducers: {
    resetVaults: (state: State) => {
      state.value = []
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVaults.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })
    builder.addCase(fetchVaults.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export const { resetVaults } = vaultsSlice.actions
export default vaultsSlice.reducer

export const selectVaults = (s: RootState) => s.app.vaults.value
export const selectVaultsBusy = (s: RootState) => s.app.vaults.status === 'busy'
export const selectVaultsLoaded = (s: RootState) => s.app.vaults.loadedOnce
export const selectVaultByAddress = (vaultAddress: string) => (s: RootState) =>
  s.app.vaults.value.find((vault: UnifiedVault) => vault.meta.networkAddress === vaultAddress)
export const selectVaultByCoinType = (coinType: string) => (s: RootState) =>
  s.app.vaults.value.find((vault: UnifiedVault) => vault.coinType === coinType)

export const getVaultByCoinType = (coinType: string, vaults: UnifiedVault[]) =>
  vaults.find((vault: UnifiedVault) => vault.coinType === coinType)

export const selectIchiVaultsWithPositions = createSelector(
  [selectVaults, selectWalletBalances, selectStakedBalances, selectVaultsLoaded],
  (vaults, balances, stakedBalances, vaultsLoaded) => {
    if (!vaultsLoaded) return []
    return vaults
      .filter((v) => v.coinType === LIQUID_USDT)
      .map((v) => {
        const coinType = v.ichiVault?.token_id_name
        //TODO: figure out decimals and pricing for LP tokens
        const balance = v.ichiVault && balances.find((b) => b.coinType === coinType)?.amount
        const balanceInt = balance ? Number(balance) : 0
        const stakedBalance = stakedBalances[v.coinType] || 0
        const walletPlusStakedBal = balanceInt + stakedBalance
        const value = walletPlusStakedBal * v.assetPrice
        const userPosition = {
          balance: walletPlusStakedBal || 0,
          value: value / 10 ** 9 || 0
        }

        return walletPlusStakedBal ? { ...v, userPosition } : v
      })
  }
)

export const selectLPNamesFromVaults = createSelector([selectVaults], (vaults) => {
  return vaults.map((v) => v.ichiVault?.token_id_name).filter((n) => n !== undefined)
})

export const selectUnifiedVaults = createSelector(
  [selectBrokersAsUnified, selectIchiVaultsWithPositions],
  (brokers, vaults) => {
    return [...brokers, ...vaults]
  }
)

export const selectUnifiedVaultsWithBalances = createSelector(
  [selectUnifiedVaults, selectWalletBalances],
  (vaults, balances) => {
    return vaults
      .filter((v) => v.meta)
      .map((v) => {
        const balance = getWalletBalance(balances, v.coinType)
        const walletBalance = balance ? balance : 0
        return { ...v, walletBalance }
      })
  }
)

export const selectUnifiedVaultsLoaded = createSelector(
  [selectVaultsLoaded, selectBrokersLoaded],
  (vaultsLoaded, brokersLoaded) => {
    return vaultsLoaded && brokersLoaded
  }
)

export interface CoinTypeWithDecimals {
  coinType: string
  decimals: number
}

export const selectCoinTypesWithDecimals = createSelector([selectUnifiedVaults], (vaults) => {
  return vaults.map((v) => ({ coinType: v.coinType, decimals: v.decimals }))
})

export const selectVaultCoinTypes = createSelector([selectVaults], (vaults) => {
  return vaults.map((v) => v.coinType)
})
