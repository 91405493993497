import DataPoint, { DataPointProps } from 'components/common/DataPoint'
import { UnifiedVault } from 'state/types'
import { formatCurrency } from 'toolbox/format'

export function VaultDataPoints({ vault }: { vault: UnifiedVault | null }) {
  if (!vault) return null
  const { supplyAPR, tvlUSD } = vault
  const isSupplyAPRPositive = supplyAPR > 0

  const dataLeft: DataPointProps[] = [
    {
      label: 'Annualized return',
      subValue: {
        subVal: supplyAPR,
        isPostive: isSupplyAPRPositive
      },
      tooltip: 'Annualized return'
    },
    {
      label: 'TVL',
      value: formatCurrency(tvlUSD),
      tooltip: 'Total Value Locked'
    }
  ]

  // const dataRight: DataPointProps[] = [
  //   {
  //     label: 'Risk Score',
  //     value: vault.meta.riskScore,
  //     tooltip: 'Risk Score'
  //   }
  // ]

  return (
    <div className="data-points">
      {dataLeft.map((dataPoint, index) => (
        <DataPoint key={index.toString() + 'left'} {...dataPoint} />
      ))}
      {/* <div className="divider" /> */}
      {/* {dataRight.map((dataPoint, index) => (
        <DataPoint key={index.toString() + 'right'} {...dataPoint} />
      ))} */}
    </div>
  )
}
