import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectActiveVault, setActiveVaultState, setFormInputState } from 'state/slices/ui/form'
import { useEffect } from 'react'
import { setVaultDetailsOpen } from 'state/slices/ui/explore'
import leftBack from 'assets/icons/left-chev-thick.svg'
import { TxForm } from 'components/form/TxForm'
import { StrategyInfo } from './StrategyInfo'
import { BarLineRateHistory } from 'components/charts/BarLineRateHistory'
import { clearBrokerHistory, selectBrokerHistoryLoaded } from 'state/slices/app/brokerHistory'
import { fetchBrokerHistory } from 'state/thunks/fetchBrokerHistory'
import { LIQUIDSWAP, MOVEPOSITION } from 'state/types'

import { selectPricesFromBrokers } from 'state/slices/app/brokers'
import { RewardsPill, RewardsPillProps } from 'components/common/RewardsPill'
import { VaultDataPoints } from './VaultDataPoints'
import { useEnvironment } from 'App'
import { TokenPill } from 'components/common/TokenPill'

export function VaultDetails() {
  const dispatch = useAppDispatch()
  const activeVault = useAppSelector(selectActiveVault)
  const historyLoaded = useAppSelector(selectBrokerHistoryLoaded)
  const prices = useAppSelector(selectPricesFromBrokers)
  const isPositionAPI = activeVault?.meta.investmentType === MOVEPOSITION
  const { config } = useEnvironment()

  useEffect(() => {
    //TODO: update dates on history fetch
    //TODO: check params on API to limit fetch size

    const hasName = activeVault?.assetName

    const shouldFetch = hasName && isPositionAPI

    if (shouldFetch) {
      dispatch(clearBrokerHistory())

      const toDate = new Date()
      const fromDate = new Date(toDate)
      fromDate.setMonth(toDate.getMonth() - 1)

      dispatch(
        fetchBrokerHistory({
          name: activeVault.assetName,
          from: fromDate,
          to: toDate,
          offset: 0,
          size: 100,
          url: config.positionApiUrl
        })
      )
    }
  }, [activeVault, dispatch, isPositionAPI, config.positionApiUrl])

  if (!activeVault) return <div>Vault not found</div>

  const { assetName } = activeVault

  const closeDetails = () => {
    dispatch(setVaultDetailsOpen(false))
    dispatch(clearBrokerHistory())
    setActiveVaultState(null)
    setFormInputState('')
  }

  const rewardsPillData: RewardsPillProps = {
    rewardsList: prices,
    prices: prices,
    tokenName: assetName
  }

  const isLiquidSwap = activeVault.meta.investmentType === LIQUIDSWAP

  return (
    <div className="vault-details">
      <div className="section-wrapper">
        <div className="section ">
          <div className="top-details">
            <img src={leftBack} alt="back" className="back-chev" onClick={closeDetails} />
            <TokenPill
              logo={activeVault.meta.iconURL}
              ticker={`${activeVault.assetName.toUpperCase()} vault`}
              clear
            />
          </div>

          <div className="bottom-details">
            <div className="left-details">
              {!isPositionAPI && (
                <div className="chart-container no-data">
                  <p>History data coming soon for {activeVault.assetName.toUpperCase()}</p>
                </div>
              )}
              {isPositionAPI && <BarLineRateHistory isLoaded={historyLoaded} />}
              <div className="vault-row flat">
                <div className="left">
                  <VaultDataPoints vault={activeVault} />
                </div>
              </div>
              <hr className="hr" />
              <StrategyInfo strategy={activeVault.meta.description} />
              {/* <hr className="hr" /> */}
            </div>
            <div className="right-details">
              <TxForm isDetails />
              {/* <RewardsPill {...rewardsPillData} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
