import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, dispatch } from 'state/store'
import { UnifiedVault } from 'state/types'
import { TabProps } from 'components/common/Tab'
import { setTxErrorState } from './transaction'
import { doTxLiquid } from 'state/thunks/doTxLiquid'

export const DEPOSIT_TAB = 'Deposit'
export const WITHDRAW_TAB = 'Withdraw'

export type FormTab = typeof DEPOSIT_TAB | typeof WITHDRAW_TAB

export const MULTI_PENDING = 'Pending'
export const MULTI_COMPLETED = 'Completed'
export const MULTI_NOT_COMPLETED = 'Not completed'

export type StepStatus = typeof MULTI_PENDING | typeof MULTI_COMPLETED | typeof MULTI_NOT_COMPLETED

export interface Step {
  label: string
  status: StepStatus
}

export type TxMultiStep = Step[]

export const LIQUID_MULTI_STEPS: TxMultiStep = [
  { label: 'Deposit', status: MULTI_NOT_COMPLETED },
  { label: 'Stake and Subscribe', status: MULTI_NOT_COMPLETED }
]

export const LIQUID_MULTI_INFO = `To complete depositing and start getting rewards, you'll need to go through several steps, with each one requiring individual approval in an external wallet.`

export interface FormState {
  tab: FormTab
  isManage: boolean
  formOpen: boolean
  activeVault: UnifiedVault | null
  formInput: string
  slippage: number
  isReview: boolean
  activeTokenQuickDeposit: string | null
  quickDepositInput: string
  tokenSelectOpen: boolean
  multiStep: TxMultiStep
}

export const initialState: FormState = {
  tab: DEPOSIT_TAB,
  isManage: false,
  formOpen: false,
  activeVault: null,
  formInput: '',
  slippage: 0.01,
  isReview: false,
  activeTokenQuickDeposit: null,
  quickDepositInput: '',
  tokenSelectOpen: false,
  multiStep: LIQUID_MULTI_STEPS
}

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTab: (s, a: PayloadAction<FormTab>) => {
      s.tab = a.payload
      s.formInput = ''
    },
    setIsManage: (s, a: PayloadAction<boolean>) => {
      s.isManage = a.payload
    },
    setFormOpen: (s, a: PayloadAction<boolean>) => {
      s.formOpen = a.payload
      s.formInput = ''
      s.isReview = false
      s.tab = DEPOSIT_TAB
      s.multiStep = LIQUID_MULTI_STEPS
    },
    setActiveVault: (s, a: PayloadAction<UnifiedVault | null>) => {
      s.activeVault = a.payload
      s.tokenSelectOpen = false
    },
    setFormInput: (s, a: PayloadAction<string>) => {
      s.formInput = a.payload
    },
    setSlippage: (s, a: PayloadAction<number>) => {
      s.slippage = a.payload
    },
    setIsReview: (s, a: PayloadAction<boolean>) => {
      s.isReview = a.payload
    },
    setActiveTokenQuickDeposit: (s, a: PayloadAction<string | null>) => {
      s.activeTokenQuickDeposit = a.payload
      if (a.payload) {
        s.tokenSelectOpen = false
      }
    },
    setQuickDepositInput: (s, a: PayloadAction<string>) => {
      s.quickDepositInput = a.payload
    },
    setTokenSelectOpen: (s, a: PayloadAction<boolean>) => {
      s.tokenSelectOpen = a.payload
      if (!a.payload) {
        s.quickDepositInput = ''
      }
    },
    setMultiStep: (s, a: PayloadAction<TxMultiStep>) => {
      s.multiStep = a.payload
    },
    updateMultiStep: (s, a: PayloadAction<{ index: number; status: StepStatus }>) => {
      const { index, status } = a.payload
      s.multiStep[index].status = status
    },
    resetMultiStep: (s) => {
      s.multiStep = LIQUID_MULTI_STEPS
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doTxLiquid.rejected, (s) => {
      s.multiStep = s.multiStep.map((step) =>
        step.status === MULTI_PENDING ? { ...step, status: MULTI_NOT_COMPLETED } : step
      )
    })
  }
})

export const selectTab = (s: RootState) => s.ui.form.tab
export const selectIsManage = (s: RootState) => s.ui.form.isManage
export const selectFormOpen = (s: RootState) => s.ui.form.formOpen
export const selectActiveVault = (s: RootState) => s.ui.form.activeVault
export const selectFormInput = (s: RootState) => s.ui.form.formInput
export const selectSlippage = (s: RootState) => s.ui.form.slippage
export const selectIsReview = (s: RootState) => s.ui.form.isReview
export const selectActiveTokenQuickDeposit = (s: RootState) => s.ui.form.activeTokenQuickDeposit
export const selectQuickDepositInput = (s: RootState) => s.ui.form.quickDepositInput
export const selectTokenSelectOpen = (s: RootState) => s.ui.form.tokenSelectOpen
export const selectMultiStep = (s: RootState) => s.ui.form.multiStep
export default slice.reducer

//selectors
const {
  setTab,
  setIsManage,
  setFormOpen,
  setActiveVault,
  setFormInput,
  setSlippage,
  setIsReview,
  setActiveTokenQuickDeposit,
  setQuickDepositInput,
  setTokenSelectOpen,
  setMultiStep,
  updateMultiStep,
  resetMultiStep
} = slice.actions

//dispatches
export const setTabState = (tab: FormTab) => {
  dispatch(setTab(tab))
}
export const setIsManageState = (isManage: boolean) => {
  dispatch(setIsManage(isManage))
}
export const setFormOpenState = (formOpen: boolean) => {
  dispatch(setFormOpen(formOpen))
}
export const setActiveVaultState = (activeVault: UnifiedVault | null) => {
  dispatch(setActiveVault(activeVault))
}

export const setFormInputState = (formInput: string) => {
  dispatch(setFormInput(formInput))
}
export const setSlippageState = (slippage: number) => {
  dispatch(setSlippage(slippage))
}
export const setIsReviewState = (isReview: boolean) => {
  dispatch(setIsReview(isReview))
}
export const setActiveTokenQuickDepositState = (activeTokenQuickDeposit: string | null) => {
  dispatch(setActiveTokenQuickDeposit(activeTokenQuickDeposit))
}
export const setQuickDepositInputState = (quickDepositInput: string) => {
  dispatch(setQuickDepositInput(quickDepositInput))
}
export const setTokenSelectOpenState = (tokenSelectOpen: boolean) => {
  dispatch(setTokenSelectOpen(tokenSelectOpen))
}
export const setMultiStepState = (multiStep: TxMultiStep) => {
  dispatch(setMultiStep(multiStep))
}
export const updateMultiStepState = (index: number, status: StepStatus) => {
  dispatch(updateMultiStep({ index, status }))
}
export const resetMultiStepState = () => {
  dispatch(resetMultiStep())
}

const formLabels: FormTab[] = [DEPOSIT_TAB, WITHDRAW_TAB]

export const buildFormTabs = (labels: FormTab[], selected: FormTab) => {
  const dashTabs: TabProps[] = labels.map((label) => ({
    label,
    active: label === selected,
    onClick: () => dispatch(setTab(label))
  }))
  return dashTabs
}

export const selectBuiltFormTabs = createSelector([selectTab], (selectedTab) => {
  const tabs = buildFormTabs(formLabels, selectedTab)
  return tabs
})
