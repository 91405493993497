import { Aptos, AptosConfig, Network } from '@aptos-labs/ts-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EnvironmentConfig } from 'state/envConfigs'
import { PoolInfo } from 'state/types'

export interface FetchPoolInfoPayload {
  config: EnvironmentConfig
}

export const fetchPoolInfo = createAsyncThunk<any, FetchPoolInfoPayload>(
  'poolInfo/fetch',
  async (payload: FetchPoolInfoPayload): Promise<PoolInfo[]> => {
    const { config } = payload
    if (!config || !config.stakeAddress) {
      console.error('Invalid payload for fetching pool info')
      return []
    }
    const aptosConfig = new AptosConfig({
      network: config.network as Network,
      fullnode: config.aptosNodeUrl,
      faucet: config.aptosFaucetUrl
    })

    if (!aptosConfig) {
      console.error('Invalid aptos config')
      return []
    }

    const aptos = new Aptos(aptosConfig)

    if (!aptos) {
      console.error('Invalid aptos client')
      return []
    }

    const pools = [
      '0x71a0ab627167f1aa11985552b60d119855a463eeb10c7f28da2aef081975df45',
      '0x36e56f9ffe148e97c66477ab64d9ca5c94edd6af450a8530bd2d4f74571ad23'
    ]

    let poolInfos: PoolInfo[] = []

    try {
      await Promise.all(
        pools.map(async (pool) => {
          const poolInfo = (await aptos.view({
            payload: {
              function: `${config.stakeAddress}::multi_rewards::get_pool_info`,
              typeArguments: [],
              functionArguments: [pool]
            }
          })) as any
          const poolAddress = pool
          const stakingToken = poolInfo[0]?.inner
          const rewardTokens = poolInfo[1].map((r: any) => r.inner)
          const totalSubscribed = poolInfo[2] ? Number(poolInfo[2]) : 0
          poolInfos.push({ poolAddress, stakingToken, rewardTokens, totalSubscribed })
        })
      )
      console.log('POOL INFOS', poolInfos)
      return poolInfos
    } catch (e: any) {
      console.error('Error fetching pool info:', e)
      return []
    }
  }
)
