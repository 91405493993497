import { useWallet } from '@aptos-labs/wallet-adapter-react'

export type NameBalanceMap = { [key: string]: number }

export type PricedInstrument = {
  /**
   * The network platform (eg Aptos)
   */
  network: string
  /**
   * The network identifier (eg an address)
   */
  networkAddress: string
  /**
   * The unique instrument's name
   */
  name: string
  /**
   * The number of decimal places for the raw token
   */
  decimals: number
  /**
   * The price of an instrument in USD
   */
  price: number
}

export type InterestRateCurve = {
  /**
   * The first juncture
   */
  u1: number
  /**
   * The second juncture
   */
  u2: number
  /**
   * Borrow rate at util = 0%
   */
  r0: number
  /**
   * Borrow rate at util = u1
   */
  r1: number
  /**
   * Borrow rate at util = u2
   */
  r2: number
  /**
   * Borrow rate at util = 100%
   */
  r3: number
}

export type Broker = {
  /**
   * The broker's network
   */
  network: string
  /**
   * The broker's network identifier
   */
  networkAddress: string
  /**
   * The network specific ID
   */
  underlyingAsset: PricedInstrument
  depositNote: PricedInstrument
  loanNote: PricedInstrument
  /**
   * Raw units
   */
  availableLiquidityUnderlying: string
  /**
   * Raw units
   */
  totalBorrowedUnderlying: string
  /**
   * Scaled units
   */
  scaledAvailableLiquidityUnderlying: string
  /**
   * Scaled units
   */
  scaledTotalBorrowedUnderlying: string
  /**
   * Current interest rate
   */
  interestRate: number
  /**
   * Underlying interest rate curve settings
   */
  interestRateCurve: InterestRateCurve
  /**
   * Maximum deposits to this broker, in raw units
   */
  maxDeposit: string
  /**
   * Scaled (normalized) max deposit
   */
  maxDepositScaled: string
  /**
   * Maximum loans from this broker, in raw units
   */
  maxBorrow: string
  /**
   * Scaled (normalized decimal) max borrow
   */
  maxBorrowScaled: string
  utilization: number
  depositNoteExchangeRate: number
  loanNoteExchangeRate: number
  loanNoteSupply: string
  depositNoteSupply: string
}

export type SBroker = Omit<
  Broker,
  'scaledAvailableLiquidityUnderlying' | 'scaledTotalBorrowedUnderlying'
> & {
  scaledAvailableLiquidityUnderlying: number
  scaledTotalBorrowedUnderlying: number
  interestFeeRate: number
  tvl: number
}

export interface ChainMeta {
  chainID: string
  logoURL: string
  name: string
  description: string
}

export interface TokenMeta {
  chainID: string
  logo: string
  ticker: string
  name: string
  description: string
  projectURL: string
  displayName: string
}

export interface MetaBroker extends SBroker {
  tokenMeta: TokenMeta
  chainMeta: ChainMeta
}

export interface FormattedPositions {
  collaterals: NameBalanceMap
  liabilities: NameBalanceMap
}

type SignAndSubmitTransactionArgs = Parameters<
  ReturnType<typeof useWallet>['signAndSubmitTransaction']
>
type SignAndSubmitTransactionReturnType = ReturnType<
  ReturnType<typeof useWallet>['signAndSubmitTransaction']
>

export type SignAndSubmitTransactionCallback = (
  ...args: SignAndSubmitTransactionArgs
) => SignAndSubmitTransactionReturnType

export type BasicPosition = {
  /**
   * Type string
   */
  instrumentId: string
  /**
   * Raw token units
   */
  amount: string
}

export type BasicPortfolioState = {
  collaterals: Array<BasicPosition>
  liabilities: Array<BasicPosition>
}

export interface TransactionArgs {
  network: string
  /**
   * Broker identifier on the blockchain
   */
  brokerName: string
  /**
   * u64 in raw token units
   */
  amount: string
  /**
   * Current state of portfolio before borrow
   */
  currentPortfolioState: BasicPortfolioState
  signerPubkey: string
}

export interface WaitArgs {
  transactionHash: string
  options: {
    checkSuccess: boolean
  }
}

export interface AccountArgs {
  accountAddress: string
  options?: any
}

export type PacketResponse = {
  packet: string
}

export type RawInstrument = {
  network: string
  networkAddress: string
  name: string
  /**
   * The decimal places for the raw token
   */
  decimals: number
}

export type PositionSuperCore = {
  instrument: RawInstrument
  amount: string
  scaledAmount: string
}

export type SPosition = Omit<PositionSuperCore, 'scaledAmount'> & {
  scaledAmount: number
}

export interface SPortfolio {
  id: string
  collaterals: SPosition[]
  liabilities: SPosition[]
}

export interface EMarket {
  marketAddress: string
  marketCoin: string
  marketData: {
    asset_mantissa: string
    asset_name: string
    asset_type: string
    borrow_cap: string
    collateral_factor_bps: string
    efficiency_mode_id: number
    extend_ref: {
      self: string
    }
    initial_liquidity: string
    interest_rate_index: {
      v: string
    }
    interest_rate_last_update_seconds: string
    interest_rate_model_type: string
    paused: boolean
    supply_cap: string
    total_cash: string
    total_liability: string
    total_reserve: string
    total_shares: string
  }
  marketType: string
  price: number
  supplyAPR: number
}

export interface IchiVaultDetails {
  bin_step: string
  coin_x_address: string
  coin_y_address: string
  collection: string
  created_timestamp: string
  deposit_count: number
  fee_apr_1_day_u18: string
  fee_apr_3_day_u18: string
  fee_apr_7_day_u18: string
  fee_apr_30_day_u18: string
  holder_count: number
  id: string
  is_x_deposit: boolean
  lp_apr_1_day_u18: string
  lp_apr_3_day_u18: string
  lp_apr_7_day_u18: string
  lp_apr_30_day_u18: string
  owner_address: string
  rebalance_count: number
  snapshot_count: number
  token_id_creator: string
  token_id_name: string
  total_fees: string
  total_shares: string
  total_value_locked_in_y_u18: string
  total_value_locked_x: string
  total_value_locked_y: string
  transaction_block_height: string
  transaction_version: string
  vault_address: string
  withdraw_count: number
  __typename: string
}

export interface UnifiedVault {
  coinType: string
  supplyAPR: number
  decimals: number
  assetPrice: number
  assetName: string
  totalDeposit: number
  totalBorrow: number
  totalShares: number
  tvlUSD: number
  meta: CanopyMeta
  walletBalance?: number
  userPosition?: UnifiedPosition
  ichiVault?: IchiVaultDetails
}

export const MOVEPOSITION = 'moveposition_lending'
export const ECHELON = 'echelon_lending'
export const LIQUIDSWAP = 'ichi_vault_liquidswap'

export type VaultType = typeof MOVEPOSITION | typeof ECHELON | typeof LIQUIDSWAP

export const TYPE_COLOR_MAP = {
  [MOVEPOSITION]: 'blue',
  [ECHELON]: 'pink',
  [LIQUIDSWAP]: 'yellow'
}

export interface WaitArgs {
  transactionHash: string
  options: {
    checkSuccess: boolean
  }
}

export interface AccountArgs {
  accountAddress: string
  options?: any
}

export interface CanopyMeta {
  additionalMetadata: any[]
  chainId: number
  description: string
  displayName: string
  iconURL: string
  id: string
  investmentType: VaultType
  labels: string[]
  networkAddress: string
  networkType: string
  paused: boolean
  priority: number
  riskScore: number
}

export interface UnifiedPosition {
  balance: number
  value: number
}

export interface PoolInfo {
  poolAddress: string
  stakingToken: string
  rewardTokens: string[]
  totalSubscribed: number
}

export interface DepositArgs {                                                                                                                                                                                                                                                
  sender: string
  coinTypeX: string
  coinTypeY: string
  binStep: string
  vault: string
  activeBinDesired: number
  activeBinSlippage: number
  amountX: bigint
  amountY: bigint
  minShares: bigint
}

export interface WithdrawArgs {
  sender: string
  coinTypeX: string             
  coinTypeY: string
  binStep: string
  vault: string
  amount: bigint
}

export interface StakeAndSubscribeArgs {
  sender: string
  pools: string[]
  creator: string
  collection: string
  name: string
  amount: string
  propertyVersion: string
}

export interface StakeArgs {
  sender: string
  creator: string
  collection: string
  name: string
  amount: string
  propertyVersion: string
}

export interface ClaimArgs {
  sender: string
  stakingTokens: string[]
}
