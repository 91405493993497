import { Aptos, AptosConfig, Network } from '@aptos-labs/ts-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EnvironmentConfig } from 'state/envConfigs'

export interface FetchStakedBalancesPayload {
  config: EnvironmentConfig
  userAddress: string
  vaultCoinTypes: string[]
}

export const fetchStakedBalances = createAsyncThunk<any, FetchStakedBalancesPayload>(
  'stakedBalances/fetch',
  async (payload: FetchStakedBalancesPayload) => {
    const { config, userAddress, vaultCoinTypes } = payload
    if (!config || !config.stakeAddress || !userAddress || vaultCoinTypes.length === 0) {
      console.error('Invalid payload for fetching staked rewards')
      return {}
    }
    const aptosConfig = new AptosConfig({
      network: config.network as Network,
      fullnode: config.aptosNodeUrl,
      faucet: config.aptosFaucetUrl
    })

    if (!aptosConfig) {
      console.error('Invalid aptos config')
      return {}
    }

    const aptos = new Aptos(aptosConfig)

    if (!aptos) {
      console.error('Invalid aptos client')
      return {}
    }

    let stakedBalances: { [key: string]: number } = {}

    try {
      await Promise.all(
        vaultCoinTypes.map(async (coinType) => {
          const poolToken = (await aptos.view({
            payload: {
              function: `${config.stakeAddress}::multi_rewards::get_pool_reward_tokens`,
              typeArguments: [],
              functionArguments: [
                '0x36e56f9ffe148e97c66477ab64d9ca5c94edd6af450a8530bd2d4f74571ad23'
              ]
            }
          })) as any[][]
          const poolToken0 = poolToken[0]

          if (!poolToken0) {
            console.error('Pool token not found')
            return
          }

          const innerToken = poolToken0[0]

          if (!innerToken?.inner) {
            console.error('Inner token not found')
            return
          }

          //TODO: dynamic staked token

          const HARD_CODE_TOKEN =
            '0xdaf28c5ba0736813bdd1a6d225ce787ee9f4ed22c3bfc660297a4e2a343d620b'

          const balance = await aptos.view({
            payload: {
              function: `${config.stakeAddress}::multi_rewards::get_user_staked_balance`,
              typeArguments: [],
              functionArguments: [userAddress, HARD_CODE_TOKEN]
            }
          })
          stakedBalances = {
            ...stakedBalances,
            [coinType]: Number(balance[0])
          }
        })
      )
      return stakedBalances
    } catch (e: any) {
      console.error('Error fetching staked rewards:', e)
      return {}
    }
  }
)
