import { Aptos } from '@aptos-labs/ts-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { CanopyMeta, IchiVaultDetails, UnifiedVault } from 'state/types'
import { findMetaByVaultAddress } from 'state/slices/app/canopyMeta'
import { getIndexerClient } from 'state/graphql/apollo'
import { GET_INDEXER_VAULTS } from 'state/graphql/gqlQueries'
import { convertU18ToPercent } from 'toolbox/format'

export const fetchVaults = createAsyncThunk(
  'vaults/fetch',
  async (
    { nodeUrl, aptos, canopyMeta }: { nodeUrl: string; aptos: Aptos; canopyMeta: CanopyMeta[] },
    { dispatch }
  ) => {
    console.log('fetching vaults...')

    if (!nodeUrl) {
      throw new Error('Node URL not set for liquidswap vaults sdk')
    }

    try {
      let apollo = getIndexerClient()
      const vaultsRes = await apollo.query({
        query: GET_INDEXER_VAULTS
      })
      const vaults = vaultsRes.data.vaults
      const USDT_PRICE = 1
      //TODO: price here
      const unifiedVaults = vaults.map((v: IchiVaultDetails) =>
        formatIchiVaultToUnified(v, USDT_PRICE, canopyMeta)
      )

      return unifiedVaults
    } catch (e: any) {
      console.error(e)
      return []
    }
  }
)

export const formatIchiVaultToUnified = (
  vault: IchiVaultDetails,
  assetPrice: number = 0,
  canopyMeta: CanopyMeta[]
): UnifiedVault => {
  const feeAPR = convertU18ToPercent(vault.fee_apr_1_day_u18)
  const lpAPR = convertU18ToPercent(vault.lp_apr_1_day_u18)
  const totalAPR = feeAPR + lpAPR
  const tvlUSD = Number(vault.total_value_locked_in_y_u18) / Math.pow(10, 18)

  const isX = vault.is_x_deposit

  const coinType = isX ? vault.coin_x_address : vault.coin_y_address
  const totalDeposit = isX ? Number(vault.total_value_locked_x) : Number(vault.total_value_locked_y)
  const meta = findMetaByVaultAddress(vault.vault_address, canopyMeta)

  const decimals = LIQUIDSWAP_DECIMALS_MAP[coinType as keyof typeof LIQUIDSWAP_DECIMALS_MAP]

  const modifiedMeta =
    meta?.displayName === 'USDT-APTOS'
      ? {
          ...meta,
          iconURL: 'https://ichi-images.s3.us-east-1.amazonaws.com/tokens/logo_256_usdt_v2.svg'
        }
      : meta

  //TODO: decimals
  //TODO: supplyAPR
  return {
    coinType,
    supplyAPR: 0.2915,
    decimals,
    assetPrice,
    assetName: meta?.displayName || '',
    totalDeposit,
    totalBorrow: 0,
    totalShares: Number(vault.total_shares),
    tvlUSD,
    meta: modifiedMeta as CanopyMeta,
    ichiVault: vault
  }
}

export const LIQUID_USDT =
  '0x43417434fd869edee76cca2a4d2301e528a1551b1d719b75c350c3c97d15b8b9::coins::USDT'
export const LIQUID_APT = '0x1::aptos_coin::AptosCoin'

export const LIQUIDSWAP_COIN_TYPES = [LIQUID_USDT]

export const LIQUIDSWAP_DECIMALS_MAP = {
  [LIQUID_USDT]: 6,
  [LIQUID_APT]: 8
}
