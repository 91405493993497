import { createAsyncThunk } from '@reduxjs/toolkit'
import { logToastThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { SignAndSubmitTransactionCallback, UnifiedVault } from 'state/types'
import { postTransactionRefresh } from 'state/fetch'
import {
  DEPOSIT_TAB,
  FormTab,
  LIQUID_MULTI_STEPS,
  MULTI_COMPLETED,
  MULTI_PENDING,
  resetMultiStepState,
  setFormOpenState,
  TxMultiStep,
  updateMultiStepState
} from 'state/slices/ui/form'
import { scaleUp } from 'toolbox/format'
import { setTxSuccessState, txStatusIdle, txStatusPending } from 'state/slices/ui/transaction'
import { Aptos, Hex, Network, PublicKey } from '@aptos-labs/ts-sdk'
import {
  normalizeHexAddress,
  signAndSubmitterBasic,
  updateSuccessStateAndWait
} from 'toolbox/aptos-util'
import { LIQUID_APT } from './fetchVaults'
import { CoinTypeWithDecimals } from 'state/slices/app/vaults'
import { AptosTx } from 'toolbox/aptosTx'

export interface TxReqPayloadClaim {
  address: string
  signAndSub: SignAndSubmitTransactionCallback
  aptos: Aptos
  envNetwork: Network
  API_URL: string
  nodeUrl: string
  coinTypeWithDecimals: CoinTypeWithDecimals[]
  stakeAddress: string
}

const ICHI_ROOT = '0x3356bce7af6eb2c332a6e944eb54c6383fec0d099af62e1618a29c7ea8e6cbc7'

export const doClaimTx = createAsyncThunk('rewards/doClaimTx', async (payload: any) => {
  toastLoad('Building transaction...')
  txStatusPending({ type: 'Claim', info: 'Signing transaction...' })

  const { envNetwork, aptos } = payload

  console.log('DO CLAIM TX PAYLOAD', payload)

  if (!envNetwork || !aptos) {
    logToastThrow('Missing required system configuration', 'Check the console for more information')
  }

  const aptosTx = new AptosTx(payload.vaultsAddress, payload.stakeAddress)

  if (!payload.nodeUrl || !envNetwork || !aptos) {
    console.log('Env network: ', envNetwork)
    console.log('aptosSDK: ', aptos)
    logToastThrow('Missing required system configuration', 'Check the console for more information')
  }

  const { address, API_URL } = payload
  if (!address) {
    logToastThrow(
      'Missing arguments',
      'The transaction request is missing required arguments, please try again'
    )
  }

  try {
    const tx = aptosTx.claimIx({
      sender: address,
      stakingTokens: ['0xdaf28c5ba0736813bdd1a6d225ce787ee9f4ed22c3bfc660297a4e2a343d620b']
    })
    console.log('claim tx', tx)

    let options = {
      gasUnitPrice: 100,
      maxGasAmount: 100000000
    }

    const hash = await signAndSubmitterBasic({
      signAndSub: payload.signAndSub,
      ixData: tx,
      options
    })

    const claimResult = await updateSuccessStateAndWait({
      hash: hash.hash,
      aptos,
      action: payload.formTab
    })
    console.log('claim result', claimResult)
    toastSuccess('Transaction completed!')

    postTransactionRefresh(
      address,
      payload.coinTypeWithDecimals,
      API_URL,
      aptos,
      payload.echelonRootAddress,
      payload.nodeUrl
    )
    txStatusIdle()
  } catch (e: any) {
    console.error(e)
    logToastThrow('Error claiming rewards', e.message)
  }
})
