import { createAsyncThunk } from '@reduxjs/toolkit'
import { Aptos, MoveResource } from '@aptos-labs/ts-sdk'
import { isEmptyOrNil } from 'toolbox/account'
import { CoinTypeWithDecimals } from 'state/slices/app/vaults'

export interface FetchWalletBalancesPayload {
  address: string
  coinTypeWithDecimals: CoinTypeWithDecimals[]
  aptos: Aptos
}

// /** Get coin balances, in raw token units */
// async function getCoinBalances(
//   ownerAddress: string,
//   coinTypes: string[],
//   aptos: Aptos
// ): Promise<string[]> {
//   const c = new CoinClient(aptos)
//   const bs = await Promise.all(
//     // The CoinClient throws if there is no registered coin type for the address
//     coinTypes.map((ct) => c.checkBalance(ownerAddress, { coinType: ct }).catch((e) => BigInt(0)))
//   )
//   return bs.map((b) => b.toString())
// }

export interface WalletBalance {
  coinType: string
  amount: number
  decimals: number
  scaledAmount: number
}

export const fetchWalletBalances = createAsyncThunk(
  'walletBalances/fetchAll',
  async (payload: FetchWalletBalancesPayload): Promise<WalletBalance[]> => {
    const aptos = payload.aptos

    if (!aptos) {
      throw new Error('Aptos client not initialized')
    }

    const { coinTypeWithDecimals, address } = payload

    if (isEmptyOrNil(coinTypeWithDecimals)) {
      console.error('No coinTypes provided for fetching wallet balances')
      // return []
    }

    let coinTypes: string[] = []

    coinTypeWithDecimals.forEach((c) => {
      coinTypes.push(c.coinType)
    })

    console.log('Coin Types: ', coinTypes)

    // if (ECH_FAUCET_COIN_TYPES) {
    //   coinTypes.push(...ECH_FAUCET_COIN_TYPES)
    // }

    // if (LIQUIDSWAP_COIN_TYPES) {
    //   coinTypes.push(...LIQUIDSWAP_COIN_TYPES)
    // }

    try {
      const resources = await aptos.getAccountResources({ accountAddress: address })
      console.log('Account Resources: ', resources)

      const tokens = await aptos.getAccountOwnedTokens({ accountAddress: address })
      console.log('Tokens: ', tokens)
      const tokenBalances = tokenFormatter(tokens)
      console.log('Token Balances: ', tokenBalances)
      const coins = resources.filter((r) =>
        coinTypes.some((coinType) => {
          return (
            r.type === `0x1::coin::CoinStore<${coinType}>` ||
            (r.type.includes(coinType) && !r.type.includes('Restricted'))
          )
        })
      )
      console.log('Filtered Coins: ', coins)

      const balances = balancesFormatter(coins, coinTypeWithDecimals)
      console.log('Coin Balances: ', balances)
      return [...balances, ...tokenBalances]
    } catch (error) {
      console.error('Error fetching tokens:', error)
      throw error
    }
  }
)

interface Coin {
  type: string
  data: {
    coin: {
      value: number
    }
  }
}

export const tokenFormatter = (tokens: any[]): WalletBalance[] => {
  return tokens.map((t) => {
    //TODO: update decimals
    const DEC = 6
    return {
      coinType: t.current_token_data.token_name,
      amount: t.amount,
      decimals: DEC,
      scaledAmount: t.amount / 10 ** DEC
    }
  })
}

export const balancesFormatter = (
  coins: MoveResource[],
  coinTypeWithDecimals: CoinTypeWithDecimals[]
): WalletBalance[] => {
  return coins
    .map((c) => {
      const coin = c as Coin // Type assertion or proper initialization
      if (!coin.data?.coin) {
        return null
      }
      const amount = coin.data?.coin?.value
      if (!amount) {
        return null
      }
      const coinType = coin.type.replace(/^0x1::coin::CoinStore<(.+)>$/, '$1')
      let decimals = coinTypeWithDecimals.find((c) => c.coinType === coinType)?.decimals || 0

      const scaledAmount = Number(amount) / 10 ** decimals
      return {
        coinType: coinType,
        amount: Number(amount),
        decimals: decimals,
        scaledAmount
      }
    })
    .filter((balance): balance is WalletBalance => balance !== null)
}
