import { Aptos, AptosConfig, Network } from '@aptos-labs/ts-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EnvironmentConfig } from 'state/envConfigs'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { GET_CANOPY_METADATA, GET_INDEXER_VAULTS } from 'state/graphql/gqlQueries'

export interface FetchIndexerPayload {
  config: EnvironmentConfig
}

export const fetchIndexer = createAsyncThunk<any, FetchIndexerPayload>(
  'indexer/fetch',
  async (payload: FetchIndexerPayload) => {
    const { config } = payload
    if (!config || !config.stakeAddress) {
      console.error('Invalid payload for fetching staked rewards')
      return {}
    }
    const aptosConfig = new AptosConfig({
      network: config.network as Network,
      fullnode: config.aptosNodeUrl,
      faucet: config.aptosFaucetUrl
    })

    const aptos = new Aptos(aptosConfig)

    const stakedBalances: { [key: string]: number } = {}

    const indexerURL = process.env.REACT_APP_INDEXER_URL

    let apollo = new ApolloClient({
      uri: indexerURL,
      cache: new InMemoryCache(),
      headers: {
        'api-key': process.env.REACT_APP_INDEXER_API_KEY || '',
        'Content-Type': 'application/json'
      }
    })

    try {
      const res = await apollo.query({
        query: GET_INDEXER_VAULTS
      })
      console.log('INDEXER VAULTS:', res)
    } catch (e: any) {
      console.error('Error fetching staked rewards:', e)
      return {}
    }
  }
)
